<template>
  <ResultViewType1 show-source-smiles show-filter />
</template>

<script>
import ResultViewType1 from '@/components/ResultViews/ResultViewType1.vue';

export default {
  name: 'HitGenerationTransGANResultView',
  components: {
    ResultViewType1
  }
};
</script>
