<template>
  <v-btn
    class="ma-2"
    color="error"
    @click.stop="cancelExecution()"
  >
    Cancel Execution
  </v-btn>
</template>

<script>
import {
  showConfirmDialog,
  showSuccessDialog,
  showErrorDialog
} from '@/mixins/utils';

export default {
  name: 'CancelExecutionButton',
  props: {
    executionId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
    };
  },
  methods: {
    cancelExecution() {
      const self = this;
      showConfirmDialog(
        'Execution will be canceled',
        'This operation cannot be undone. Are you sure you want to cancel it?',
        function() {
          self.api.cancelExecution(
            self.executionId,
            function(data) {
              showSuccessDialog('', 'Execution was canceled.');
              self.$emit('refresh');
            },
            function(e) {
              console.log(e);
              showErrorDialog(
                'An unexpected error occurred',
                'Try again or let an administrator know if the problem persists.'
              );
            }
          );
        }
      );
    }
  }
};
</script>
