<template>
  <v-btn small outlined color="black" :loading="loading" :disabled="disabled" @click="downloadFile">
    <v-icon left>mdi-download</v-icon>
    {{ dlText }}
  </v-btn>
</template>

<script>
export default {
  name: 'SampleDownloadButton',
  components: {},
  props: {
    filename: {
      type: String,
      required: true
    },
    dlText: {
      type: String,
      default: 'download sample file'
    }
  },
  data() {
    return {
      loading: false,
      disabled: false
    };
  },
  methods: {
    downloadFile() {
      const self = this;
      self.disabled = true;
      self.loading = true;
      this.api.downloadFile(
        this.filename,
        function() {
          self.loading = false;
          self.disabled = false;
        },
        function(error) {
          console.log(error);
        });
    }
  }
};
</script>
