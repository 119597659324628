<template>
  <v-dialog v-model="dialog" width="600">
    <template #activator="{ on, attrs }">
      <v-tooltip :disabled="parameterInputs !== null" top>
        <template #activator="{ on: tooltipOn, attrs: tooltipAttrs }">
          <div v-bind="tooltipAttrs" v-on="tooltipOn">
            <v-btn
              v-if="isExecutor"
              class="dialog-button"
              :disabled="parameterInputs === null"
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              SEE PARAMETERS
            </v-btn>
          </div>
        </template>
        <span>この実行のパラメータを取得することが出来ません。</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Input parameters
      </v-card-title>
      <v-card-text v-if="parameterInputs !== null">
        <ExecutionInputValue
          v-for="(input, index) in parameterInputs"
          :key="index"
          :name="input.name"
          :info="input"
          @onFileDownload="downloadInputFile"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { showErrorDialog } from '@/mixins/utils';
import ExecutionInputValue from './ExecutionInputValue.vue';

export default {
  name: 'ExecutionInputs',
  components: {
    ExecutionInputValue: ExecutionInputValue
  },
  props: {
    executionId: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      parameterInputs: null,
      dialog: false,
      isExecutor: false
    };
  },
  mounted() {
    const self = this;
    if (this.executionId != null) {
      this.api.getExecutionInputs(
        self.executionId,
        function(data) {
          self.parameterInputs = data.inputs;
          self.isExecutor = true;
        }, function(error) {
          if (error.status !== 403) {
            self.isExecutor = true;
          }
        }
      );
    }
  },
  methods: {
    downloadInputFile(fileName) {
      this.api.downloadExecutionInputFile(
        this.executionId,
        fileName,
        function(error) {
          showErrorDialog('Error', error.data);
        });
    }
  }
};
</script>

<style scoped>
.dialog-button {
  margin-bottom: 5px;
}
</style>
