<template>
  <v-container>
    <v-row>
      <v-col cols="4">
        <v-select
          v-model="generationMethod"
          :items="generationMethods"
          item-value="value"
          item-text="text"
          label="Generation method"
          required
          :rules="[(v) => !!v || 'The prediction method is required']"
        >
          <template #append-outer>
            <v-tooltip right>
              <template #activator="{ on, attrs }">
                <v-icon class="help-icon" v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
              </template>
              <span v-html="methodHelp" />
            </v-tooltip>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <ExecuteFormEMPIRE v-if="generationMethod === 'empire'" />
    <ExecuteFormGx2Mol v-else-if="generationMethod === 'gx2mol'" />
  </v-container>
</template>
'Generation method': this.generationMethods.find(obj => obj.value === this.generationMethod).label,
<script>
import ExecuteFormEMPIRE from '../../components/LeadGeneration/EMPIRE/ExecuteForm.vue';
import ExecuteFormGx2Mol from '../../components/LeadGeneration/Gx2Mol/ExecuteForm.vue';

export default {
  name: 'LeadGenerationExecuteView',
  components: {
    ExecuteFormEMPIRE,
    ExecuteFormGx2Mol
  },
  data() {
    return {
      generationMethod: '',
      generationMethods: [
        { text: 'Empire', value: 'empire' },
        { text: 'Gx2Mol', value: 'gx2mol' }
      ],
      input: {}
    };
  },
  computed: {
    methodHelp: function() {
      switch (this.generationMethod) {
      case '':
        return '生成手法を選択してください。';
      case 'empire':
        return `入力された fragment の構造を、VAE と SMILES 表記された building block list<br>
                を用いて変換することにより、網羅的に scaffold<br>
                を保持した新規化合物の構造式を出力します。`;
      case 'gx2mol':
        return `ユーザーが指定した分子骨格を保持しつつ、<br>
        入力された遺伝子発現プロファイルからVAEによって抽出された特徴とRNNを用いて、<br>
        望ましい遺伝子発現プロファイルを持つことが期待される新規化合物の構造を出力します。`;
      }
      return '現在使用できません。';
    }
  },
  mounted() {
    this.checkLoggedIn(this.$session);
  }
};
</script>
