<template>
  <ResultViewType2 />
</template>

<script>
import ResultViewType2 from '@/components/ResultViews/ResultViewType2.vue';

export default {
  name: 'LeadGenerationEMPIREResultView',
  components: {
    ResultViewType2
  }
};
</script>
