<template>
  <v-container>
    <div>
      <v-row>
        <v-col cols="4">
          <v-select
            v-model="property"
            :items="properties"
            item-value="value"
            item-text="text"
            label="Desired property"
            required
            :rules="[v => !!v || 'The desired property is required']"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="epochs"
            label="Epoch"
            type="number"
            min="1"
            required
            :rules="[v => !!v || 'The type of the input is required']"
          />
        </v-col>
      </v-row>
      <v-row v-if="property=='pIC50'">
        <v-col cols="6">
          <ModuleInput
            :info="{
              description:
                'A QSAR model file (Pickle format)',
              type: 'file',
            }"
            filled
            dense
            accept=".pickle"
            name="qsar_model"
            @setFile="onSetFile"
          />
        </v-col>
        <v-col class="d-flex align-center">
          <sample-download-button :filename="module.config.sample_file" />
        </v-col>
      </v-row>

      <ExecuteButton
        :disabled="!canSubmit"
        :confirm-loading="loading"
        @confirm="onSubmit"
      />
    </div>
  </v-container>
</template>

<script>
import router from '@/router';
import ExecuteButton from '@/components/ExecuteButton.vue';
import ModuleInput from '@/components/ModuleInput';
import SampleDownloadButton from '@/components/SampleDownloadButton.vue';

export default {
  name: 'ReactionGenerationExecuteView',
  components: {
    ModuleInput,
    ExecuteButton,
    SampleDownloadButton
  },
  data() {
    return {
      id: this.$route.params.id,
      module: null,
      parameters: Object(),
      files: Object(),
      fileSelected: false,
      property: 'qed',
      epochs: 1,
      properties: [
        { text: 'QED', value: 'qed' },
        { text: 'logP', value: 'logp' },
        { text: 'Bioactivity', value: 'pIC50' }
      ],
      loading: false,
      input: {}
    };
  },
  computed: {
    canSubmit: function() {
      if (this.property === 'pIC50') {
        return this.fileSelected;
      } else {
        return true;
      }
    }
  },
  mounted() {
    this.checkLoggedIn(this.$session);
    this.loadModule();
  },
  methods: {
    loadModule() {
      const self = this;
      this.api.getMLModuleByName(
        'CasVAE',
        function(module) {
          self.module = module;
          const config = JSON.parse(module.config);
          self.module.config = config;
        },
        function(error) {
          console.log(error);
        }
      );
    },
    onSetFile(...args) {
      const [name, file] = args;
      if (file) {
        this.files[name] = file;
        this.fileSelected = true;
        this.input[name] = file.name;
      } else {
        this.fileSelected = false;
      }
    },
    onSubmit(visibility) {
      this.loading = true;
      this.$session.start();
      const self = this;
      this.input = {
        ...this.input,
        'Desired property': this.properties.find(prop => prop.value === this.property).text,
        'Epoch': this.epochs
      }
      this.api.executeMLModule(
        { name: 'CasVAE' },
        {
          'property': this.property,
          'epoch': this.epochs,
          ...visibility
        },
        self.files,
        function(response) {
          router.push({
            name: 'ReactionGenerationResultView',
            params: { id: response.id }
          });
          self.loading = false;
        },
        function(error) {
          console.log(error);
          self.loading = false;
        },
        this.input
      );
    }
  }
};
</script>
