<template>
  <div>
    <v-btn
      class="dialog-button"
      :disabled="disabled"
      color="primary"
      @click="onClickExecute"
    >
      Execute
    </v-btn>
    <v-dialog v-model="dialog" width="600">
      <v-card class="pa-5">
        <v-card-title class="break">
          Choose who can access the results of this execution.
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-form ref="form" @submit.prevent>
              <v-radio-group v-model="visibility" row>
                <v-radio
                  v-for="(option, index) in visibilityOptions"
                  :key="index"
                  :label="option.label"
                  :value="option.value"
                />
              </v-radio-group>
              <v-select
                v-if="visibility === 'MyGroup'"
                v-model="group"
                :items="userGroups"
                label="Groups"
                item-value="id"
                item-text="name"
                :rules="[(v) =>
                  (visibility === 'MyGroup' && !!v) || 'Group is required']"
                required
                dense
              />
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="alert" @click="dialog=false">Cancel</v-btn>
          <v-spacer />
          <v-btn color="warning" :loading="confirmLoading" @click="execute">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'ExecuteButton',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    validationMethod: {
      // Pass any Function that need to execute before dialog opens
      // Function must returns a boolean
      type: Function,
      default: null
    },
    confirmLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visibility: 'OnlyMe',
      group: null,
      visibilityOptions: [],
      userGroups: [],
      dialog: false
    };
  },
  mounted() {
    this.loadExecVisibilityChoices();
    this.getGroups();
  },
  methods: {
    loadExecVisibilityChoices() {
      const self = this;
      this.api.getExecutionVisibility(function(visibilityOptions) {
        self.visibilityOptions = visibilityOptions;
      }, function(error) {
        console.log(error);
      });
    },
    getGroups() {
      const currentuser = this.$session.get('user');
      this.userGroups = currentuser.groups;
    },
    execute() {
      if (this.$refs.form.validate()) {
        this.$emit(
          'confirm', {
            visibility: this.visibility,
            group: this.visibility === 'OnlyMe' ? null : this.group
          });
      }
    },
    async onClickExecute() {
      if (this.validationMethod == null) {
        this.dialog = true;
        this.loading = false;
      } else if (typeof this.validationMethod === 'function') {
        this.dialog = await this.validationMethod();
      }
    }
  }
};
</script>

<style scoped>
.break{
  word-break: normal !important;
}
</style>
