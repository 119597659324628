<template>
  <span @click="logout">Logout</span>
</template>

<script>
import router from '@/router';

export default {
  name: 'Logout',
  methods: {
    logout() {
      if (this.$session.exists()) {
        this.$session.destroy();
      }
      router.push({ name: 'Auth' });
    }
  }
};
</script>
