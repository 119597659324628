<template>
  <v-data-table
    class="elevation-1"
    :headers="headers"
    :items="members"
    :loading="isTableLoading"
    loading-text="Loading..."
    no-data-text="No data available."
    item-key="subId"
    :items-per-page="itemsPerPage"
    fixed-header
    hide-default-footer
    sort-by="username"
    multi-sort
    @pagination="onPaginationChange"
  >
    <template #top="{ pagination, options, updateOptions }">
      <v-data-footer
        :pagination="pagination"
        :options="options"
        :items-per-page-options="itemsPerPageOptions"
        items-per-page-text="$vuetify.dataTable.itemsPerPageText"
        :show-first-last-page="true"
        @update:options="updateOptions"
      />
    </template>
    <template #[`item.action`]="{ item }">
      <v-btn
        color="error"
        @click="removeMember(item.id)"
      >
        Remove
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { ITEMS_PER_PAGE_OPTIONS } from '@/env';
import {
  showSuccessDialog,
  showErrorDialog
} from '@/mixins/utils';

export default {
  name: 'GroupMembersTable',
  props: {
    groupId: {
      type: Number,
      required: true
    },
    refresh: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isTableLoading: false,
      itemsPerPage:
          Number(localStorage.getItem('grp-mem-ipp')) ||
          ITEMS_PER_PAGE_OPTIONS[0],
      itemsPerPageOptions: ITEMS_PER_PAGE_OPTIONS,
      headers: [
        { text: 'Username', value: 'username' },
        { text: 'Email address', value: 'email' },
        { text: 'Action', value: 'action', sortable: false }
      ],
      members: []
    };
  },
  watch: {
    groupId: {
      handler: function() {
        this.getGroupMembers();
      }
    },
    refresh: {
      handler: function() {
        if (this.refresh) {
          this.getGroupMembers();
          this.$emit('refreshed');
        }
      }
    }
  },
  mounted() {
    this.checkLoggedIn(this.$session);
  },
  methods: {
    onPaginationChange(pagination) {
      if (pagination.itemsPerPage) {
        this.itemsPerPage = pagination.itemsPerPage;
        localStorage.setItem('grp-mem-ipp', pagination.itemsPerPage);
      }
    },
    getGroupMembers() {
      const self = this;
      this.isTableLoading = true;
      if (this.groupId !== null) {
        this.api.getGroupMembers(this.groupId, function(response) {
          self.members = response.data;
          self.isTableLoading = false;
        }, function() {
          self.isTableLoading = false;
        });
      } else {
        this.members = [];
      }
    },
    removeMember(userId) {
      const self = this;
      this.api.removeGroupMember(this.groupId, userId, function() {
        self.getGroupMembers();
        self.$emit('memberRemoved');
        showSuccessDialog('Member was removed.');
      }, function() {
        showErrorDialog('Member was not removed.', 'An unexpected error occured');
      });
    }
  }
};
</script>
