<template>
  <div class="border-box">
    <div v-for="(info, name) in element" :key="name">
      <ModuleInput :name="name" :info="info" @changeParameter="onChangeChild" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModuleInputBlock',
  components: {
    'ModuleInput': () => import('../components/ModuleInput')
  },
  props: {
    element: {
      type: Object,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      values: {}
    };
  },
  mounted() {
    for (const name in this.item) {
      this.values[name] = this.item[name];
    }
  },
  methods: {
    onChangeChild(name, value) {
      this.$emit('changeParameter', name, value, this.index);
    }
  }
};
</script>

<style scoped>
    .indent {
        margin-left: 30px;
    }
    input {
        border: solid 1px;
    }
</style>
