const Filters = {
  'PAINS': {
    'name': 'PAINS',
    'predicted_value_name': 'pains'
  }
};

export default {
  Filters: Filters
};
