import Vue from 'vue';
import App from './App.vue';

import router from './router';
import vuetify from './plugins/vuetify';

import VueSession from 'vue-session';

import 'kekule/dist/themes/default/kekule.css';

import api from '@/api';
import AxiosPlugin from '@/plugins/axios';
import Helper from '@/plugins/helper';

import LoadScript from 'vue-plugin-load-script';

import store from '@/store';

Vue.use(VueSession, { persist: true });

Vue.config.productionTip = false;
Vue.prototype.api = api;
Vue.api = api;
Vue.use(LoadScript);
Vue.use(Helper);
Vue.use(AxiosPlugin);

/* eslint-disable no-new */
new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app');
