<template>
  <v-container>
    <v-dialog
      v-model="dialog"
      width="1200"
    >
      <div class="wrapper">
        <v-card>
          <v-card-title>
            Reaction templates
          </v-card-title>
          <div v-for="(template, template_name) in templateImages" :key="template_name">
            <v-card-subtitle class="text-h6">
              {{ template_name }}
            </v-card-subtitle>
            <v-card-text>
              <v-img
                contain
                :src="template"
              />
            </v-card-text>
          </div>
        </v-card>
        <v-card>
          <v-card-title>
            Synthetic route
          </v-card-title>
          <v-card-text>
            <v-img
              contain
              :src="reactionImage"
            />
          </v-card-text>
        </v-card>
      </div>
    </v-dialog>
    <div v-if="execution">
      <ResultCard
        :execution="execution"
        :outputs="outputs"
        @downloadOutputFile="downloadOutputFile"
        @refresh="updateExecution"
      />

      <FilterCard
        :execution="execution"
        :filters="filters"
        :has-filtered-results="csvData
          && csvData.items
          && csvData.items.length > 0"
        @selectFilters="selectFilters"
        @downloadFilteredResult="downloadFilteredResult"
      />

      <div v-if="execution['status'] == 'COMPLETE'">
        <CsvItemList
          :csv-data="csvData"
          csv-item-type="Info"
          @viewReaction="viewReaction"
        />
      </div>
    </div>
  </v-container>
</template>

<script>
import CsvItemList from '@/components/CsvItemList';
import ResultCard from '@/components/Yamanishi/ResultCard';
import FilterCard from '@/components/Yamanishi/FilterCard';
import consts from '@/store/consts';
import { showErrorDialog, showWaitingDialog } from '@/mixins/utils';

export default {
  name: 'ReactionGenerationResultView',
  components: {
    CsvItemList,
    ResultCard,
    FilterCard
  },
  data() {
    return {
      id: this.$route.params.id,
      timer: null,
      execution: null,
      lastUpdated: null,
      outputs: [],
      csvData: null,
      dialog: false,
      reactionImage: '',
      templateImages: '',
      isDisplayedDialog: false,
      filters: [consts.Filters.PAINS]
    };
  },
  mounted() {
    this.checkLoggedIn(this.$session);
    this.observeExecution();
  },
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    updateExecution() {
      const self = this;
      self.api.getExecution(self.id, function(execution) {
        self.lastUpdated = Date.now();
        self.execution = execution;
        if (execution.end_at) {
          clearInterval(self.timer);
          if (execution.status === 'COMPLETE') {
            self.setOutput();
            self.loadCompounds();
          }
        } else {
          if (!self.isDisplayedDialog) {
            showWaitingDialog();
            self.isDisplayedDialog = true;
          }
        }
      }, function(error) {
        console.log(error);
      });
    },
    observeExecution() {
      this.updateExecution();
      this.timer = setInterval(this.updateExecution, 30 * 1000);
    },
    setOutput() {
      const config = JSON.parse(this.execution.module.config);
      this.outputs = config.output;
    },
    loadCompounds() {
      const self = this;
      this.api.loadExecutionCsv(self.id, function(csvData) {
        self.csvData = csvData;
      }, function(error) {
        console.log(error);
      });
    },
    downloadOutputFile(file) {
      this.api.downloadExecutionFile(this.id, file);
    },
    viewReaction(reaction) {
      const self = this;
      this.api.postReaction(
        reaction,
        function(response) {
          self.reactionImage = `data:image/png;base64, ${response.reaction}`;
          self.templateImages = response.templates;
          self.dialog = true;
        }, function(error) {
          console.log(error);
          showErrorDialog(
            'Something went wrong.',
            'Cannot create the reaction image.'
          );
        });
    },
    selectFilters(selectedFilters) {
      const self = this;
      this.api.loadFilteredExecutionCsv(
        self.id,
        selectedFilters,
        function(csvData) {
          self.csvData = csvData;
        }, function(error) {
          console.log(error);
        });
    },
    downloadFilteredResult(selectedFilters) {
      this.api.downloadExecutionFile(this.id, this.outputs[0], selectedFilters);
    }
  }
};
</script>

<style scoped>
button {
    border: solid 1px;
    padding: 5px;
}
img {
  width: 1200px;
  height: 600px;
}
</style>
