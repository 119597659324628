<template>
  <div>
    <div id="kekule" />
  </div>
</template>

<script>
import { Kekule } from 'kekule';
export default {
  name: 'CompoundEditor',
  components: {
  },
  props: {
    compound: {
      type: Object,
      default: () => null
    }
  },
  mounted() {
    this.initComposer();
  },
  methods: {
    initComposer() {
      const kekule = document.getElementById('kekule');
      if (!kekule.hasChildNodes()) {
        const composer = new Kekule.Editor.Composer(kekule);
        composer.setDimension('500px', '400px');
        composer.setPredefinedSetting('molOnly');
        composer.getRenderConfigs()
          .getColorConfigs()
          .setUseAtomSpecifiedColor(true);
        composer.getEditorConfigs()
          .getInteractionConfigs()
          .setEditorInitialZoom(0.8);
      }
    }
  }
};
</script>
