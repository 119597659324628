import Swal from 'sweetalert2';

export const convertToSentenceCase = (string) => {
  if (string) {
    if (string === 'default') {
      return 'Average (default)';
    } else {
      return string[0].toUpperCase() + string.slice(1).replaceAll('_', ' ');
    }
  } else return '';
};

export const camelToSnake = (str) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const snakeCaseParameters = (params) => {
  const parameters = {};
  Object.keys(params).forEach(key => {
    parameters[camelToSnake(key)] = params[key];
  });
  return parameters;
};

export const paramsSerializer = (params) => {
  // Sample implementation of query string building
  const paramStrings = [];
  Object.keys(params).forEach(key => {
    if (params[key]) {
      paramStrings.push(`${key}=${encodeURIComponent(params[key])}`);
    }
  });
  return paramStrings.join('&');
};

export const showConfirmDialog = (title, html, approveCB, deniedCB) => {
  Swal.fire({
    icon: 'question',
    title: title,
    html: html || '',
    width: calculateTextWidth(html),
    showCancelButton: true,
    confirmButtonText: 'Yes',
    showDenyButton: deniedCB !== undefined,
    denyButtonText: 'No',
    customClass: {
      cancelButton: 'order-1 right-gap',
      confirmButton: 'order-2',
      denyButton: 'order-3',
      content: 'text-left-important'
    }
  }).then((result) => {
    if (result.isConfirmed) {
      approveCB();
    } else if (result.isDenied) {
      deniedCB();
    }
  });
};

export const showErrorDialog = (title, html) => {
  Swal.fire({
    icon: 'error',
    title: title || 'Error',
    html: html || '',
    showConfirmButton: true,
    showCloseButton: true
  });
};

export const showSuccessDialog = (title, html) => {
  Swal.fire({
    icon: 'success',
    title: title || 'Success',
    html: html || '',
    showConfirmButton: true,
    showCloseButton: true
  });
};

export const showWaitingDialog = () => {
  Swal.fire({
    icon: 'info',
    text: 'Running this model may take several hours. Please wait for a while.',
    showConfirmButton: false,
    showCloseButton: false,
    timer: 5000
  });
};

export const calculateTextWidth = (text) => {
  const temp = document.createElement('div');
  document.body.appendChild(temp);
  temp.style.font = 'serif';
  temp.style.fontSize = '18px';
  temp.style.height = 'auto';
  temp.style.width = 'auto';
  temp.style.minWidth = '300px';
  temp.style.position = 'absolute';
  temp.style.whiteSpace = 'no-wrap';
  temp.innerHTML = text;

  const width = Math.ceil(temp.clientWidth) + 100;

  document.body.removeChild(temp);
  return width + 'px';
};

export const truncTo = (val, n) => {
  return Math.trunc(val * Math.pow(10, n)) / Math.pow(10, n);
};

export const formatNumber = (value) => {
  if (Number.isInteger(Number(value))) {
    return parseInt(value);
  } else {
    const v = parseFloat(value);
    if (isNaN(v)) {
      return value;
    } else if (v < 1) {
      return v.toFixed(3);
    } else {
      return v.toFixed(2);
    }
  }
};

export const isEmpty = (value) => {
  return (
  // null or undefined
    (value == null) ||
        // has length and it's zero
        (Object.prototype.hasOwnProperty.call(value, 'length') && value.length === 0) ||
        // is an Object and has no keys
        (value.constructor === Object && Object.keys(value).length === 0)
  );
};

export const clone = (items) => items.map(
  item => Array.isArray(item) ? clone(item) : { ...item }
);
