<template>
  <div class="line">
    <div v-if="isObjectType()">
      <span class="label">{{ label }}:</span>
      <div class="indent border-box">
        <div
          v-for="(child_info, child_name) in info.parameters"
          :key="child_name"
        >
          <ModuleInput
            :name="child_name"
            :info="child_info"
            @changeParameter="onChangeChild"
            @setFile="onSetFile"
          />
        </div>
      </div>
    </div>
    <div v-if="isTextType()">
      <v-text-field
        v-if="info.type != 'text'"
        v-model="text"
        :label="label"
        v-bind="$attrs"
        @change="onChange"
      />
      <v-textarea
        v-else
        v-model="text"
        :label="label"
        v-bind="$attrs"
        @change="onChange"
      />
    </div>
    <div v-if="isFileType()">
      <!-- <span class="label">{{ label }}:</span> -->
      <v-file-input v-bind="$attrs" :label="label" @change="onChange" />
    </div>
    <div v-if="isListType()">
      <span class="label">
        {{ label }}:
        <v-btn
          color="primary"
          class="mx-2"
          elevation="3"
          fab
          x-small
          @click="addListItem"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-btn
          v-if="list.length > 0"
          color="error"
          elevation="3"
          fab
          x-small
          @click="removeListItem"
        >
          <v-icon>mdi-minus</v-icon>
        </v-btn>
      </span>
      <div class="indent">
        <ModuleInputBlock
          v-for="(item, index) in list"
          :key="index"
          :element="info.element"
          :item="item"
          :index="index"
          @changeParameter="onChangeChild"
        />
      </div>
    </div>
    <div v-if="isDictionaryType()">
      <span class="label">{{ label }}:</span>
      <div class="indent border-box">
        <div
          v-for="(child_info, child_name) in info.element"
          :key="child_name"
        >
          <ModuleInput
            :name="child_name"
            :info="child_info"
            @changeParameter="onChangeChild"
          />
        </div>
      </div>
    </div>
    <div v-if="isBooleanType()">
      <v-checkbox v-model="bool" :label="label" @change="onChange" />
    </div>
    <div v-if="info.type == 'label'">
      <h3>{{ info.text }}</h3>
    </div>
  </div>
</template>

<script>
import ModuleInputBlock from '../components/ModuleInputBlock';

export default {
  name: 'ModuleInput',
  components: {
    ModuleInputBlock: ModuleInputBlock
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    info: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      label: '',
      text: '',
      file: null,
      object: Object(),
      list: [],
      dictionary: {},
      bool: false,
      showOption: false
    };
  },
  mounted() {
    this.label = '';
    this.initialize();
  },
  methods: {
    isTextType() {
      // list-values is comma-separated values (= one line of text)
      return [
        'integer', 'float', 'string', 'smiles', 'text',
        'list-strings', 'list-numbers'
      ].includes(this.info.type);
    },
    isFileType() {
      return ['file'].includes(this.info.type);
    },
    isObjectType() {
      return ['yaml', 'json'].includes(this.info.type);
    },
    isListType() {
      return ['list'].includes(this.info.type);
    },
    isDictionaryType() {
      return ['dictionary'].includes(this.info.type);
    },
    isBooleanType() {
      return ['boolean'].includes(this.info.type);
    },
    onChange(e) {
      if (this.isTextType()) {
        this.$emit('changeParameter', this.name, this.text);
      } else if (this.isFileType()) {
        // TODO Do we need multiple files input?
        this.file = Array.isArray(e) ? e[0] : e;

        /**
         * Replace space with underscore in file name
         * to avoid error in the backend.
         */
        if (this.file) {
          const renamed = this.file.name.replace(/\s/g, '_');
          this.file = new File(
            [this.file], renamed, { type: this.file.type }
          );
          this.$emit('setFile', this.name, this.file);
        } else {
          this.$emit('setFile', null, null);
        }
      } else if (this.isObjectType()) {
        this.$emit('changeParameter', this.name, this.object);
      } else if (this.isListType()) {
        const value = this.list;
        this.$emit('changeParameter', this.name, value);
      } else if (this.isDictionaryType()) {
        this.$emit('changeParameter', this.name, this.dictionary);
      } else if (this.isBooleanType) {
        this.$emit('changeParameter', this.name, this.bool);
      }
    },
    onChangeChild(name, value, index = null) {
      if (
        this.info.parameters &&
                this.info.parameters[name].type === 'file'
      ) {
        this.$emit('setFile', name, value);
        return;
      }
      if (this.isObjectType()) {
        this.object[name] = value;
      } else if (this.isListType()) {
        this.list[index][name] = value;
      } else if (this.isDictionaryType()) {
        this.dictionary[name] = value;
      }

      this.onChange(null);
    },
    onSetFile(name, value) {
      const attributeName = this.name + '___' + name;
      console.log('onSetFile in input' + attributeName);
      this.$emit('setFile', attributeName, value);
    },
    addListItem() {
      this.list.push({});
    },
    removeListItem() {
      this.list.pop();
    },
    toggleOption() {
      this.showOption = !this.showOption;
    },
    initialize() {
      if ('description' in this.info) {
        this.label = this.info.description.trim();
      }
      if (this.label.length === 0) {
        this.label = this.name;
      }
      if (this.isBooleanType()) {
        this.bool = this.info.default === 'true';
      } else if (this.isTextType()) {
        this.text = this.info.default;
      } else if (this.isObjectType()) {
      // Keep until completely tested
      // for (const name in this.info.parameters) {
      //     this.object[name] = this.info.parameters[name].default;
      // }
      } else if (this.isListType()) {
        const item = {};
        for (const name in this.info.element) {
          if (this.info.element[name].default) {
            item[name] = this.info.element[name].default;
          }
        }
        this.list.push(item);
      } else if (this.isDictionaryType()) {
      // Keep until completely tested
      // for (const name in this.info.element) {
      //     this.dictionary[name] = this.info.element[name].default;
      // }
      }
      if (!this.isFileType()) {
        this.onChange();
      }
    }
  }
};
</script>

<style scoped>
.line {
    margin-top: 5px;
    margin-bottom: 5px;
}
.label {
    display: inline-block;
}
.border-box {
    padding-left: 10px;
    border-left: solid 1px;
}
.indent {
    margin-left: 50px;
}
input {
    border: solid 1px;
}
textarea {
    border: solid 1px;
    width: 500px;
}
</style>
