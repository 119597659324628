<template>
  <v-card>
    <v-card-title>{{ execution['module']['name'] }}</v-card-title>
    <v-card-subtitle>
      <v-row>
        <div class="col-sm-1">Registration</div>
        <div class="col-sm-3">{{ execution['created_at'] }}</div>
        <template v-if="execution['status'].toUpperCase() === 'CANCELED'">
          <v-icon>mdi-dots-horizontal</v-icon>
          <span class="pa-3">Execution canceled.</span>
        </template>
        <template v-else>
          <template v-if="execution['begin_at']">
            <div class="col-sm-1">Start</div>
            <div class="col-sm-3">{{ execution['begin_at'] }}</div>

            <template v-if="execution['end_at']">
              <div class="col-sm-1">End</div>
              <div class="col-sm-3">{{ execution['end_at'] }}</div>
            </template>
            <template v-else>
              <v-progress-circular
                :size="25"
                indeterminate
              />
              <span class="pa-3">Execution started, waiting for results...</span>
            </template>
          </template>
          <template v-else>
            <v-icon>mdi-dots-horizontal</v-icon>
            <span class="pa-3">In the queue, waiting for execution...</span>
          </template>
        </template>
      </v-row>
    </v-card-subtitle>

    <v-card-actions>
      <template v-if="execution['status'] == 'COMPLETE'">
        <div class="col-sm-1">Results</div>
        <v-col>
          <v-row v-for="file in outputs" :key="file">
            <v-btn @click="downloadOutputFile(file)">
              <v-icon dark light>mdi-download</v-icon>
              {{ downloadOutputFileText(file) }}
            </v-btn>
          </v-row>
        </v-col>
      </template>
      <template v-else-if="execution['end_at']">
        <div class="col-sm-1">Status</div>
        <v-col>{{ execution['status'] || 'Unknown' }}</v-col>
      </template>
      <v-spacer />
      <v-col cols="auto">
        <ExecutionInputs :execution-id="execution.id" />
      </v-col>
      <template v-if="execution.cancelable">
        <v-col>
          <CancelExecutionButton
            :execution-id="execution.id"
            @refresh="refresh"
          />
        </v-col>
      </template>
    </v-card-actions>
  </v-card>
</template>

<script>
import CancelExecutionButton from '@/components/CancelExecutionButton';
import ExecutionInputs from '@/components/ExecutionInputs.vue';

export default {
  name: 'YamanishiResultCard',
  components: {
    CancelExecutionButton,
    ExecutionInputs
  },
  props: {
    execution: {
      type: Object,
      required: true
    },
    outputs: {
      type: Array,
      required: true
    }
  },
  methods: {
    downloadOutputFileText(file) {
      const fileWithoutExt = file.split('.').slice(0, -1).join('.');
      const convertedName = fileWithoutExt.replace(/_/g, ' ');
      return `DL ${convertedName}`;
    },
    downloadOutputFile(file) {
      this.$emit('downloadOutputFile', file);
    },
    refresh() {
      this.$emit('refresh');
    }
  }
};
</script>
