<template>
  <div class="line">
    <div v-if="isObjectType()">
      <span class="label">{{ label }}:</span>
      <div class="indent border-box">
        <div v-for="child of children" :key="child.name">
          <ExecutionInputValue
            :name="child.name"
            :info="child"
            :reward-config="rewardConfig"
            @onFileDownload="downloadInputFile"
          />
        </div>
      </div>
    </div>
    <div v-if="isTextType()">
      <v-autocomplete
        v-if="name === 'origin' && rewardConfig"
        v-model="value"
        :items="rewards"
        :label="label"
        item-value="origin"
        item-text="name"
        readonly
      />
      <v-text-field
        v-else-if="type != 'text'"
        v-model="value"
        :label="label"
        readonly
      />
      <v-textarea
        v-if="type == 'text'"
        v-model="value"
        :label="label"
        readonly
      />
    </div>
    <div v-if="isListTextType()">
      <v-textarea v-model="value" :label="label" readonly />
    </div>
    <div v-if="isFileType()">
      <p>
        {{ label }}:
        <span class="downloadLink" @click="downloadInputFile(value)">
          {{ value }}
        </span>
      </p>
    </div>
    <div v-if="isListType()">
      <span class="label"> {{ label }}: </span>
      <div class="indent">
        <div v-for="(child, index) in children" :key="index">
          <div class="border-box">
            <div v-for="item of child" :key="item.name">
              <ExecutionInputValue
                :name="item.name"
                :info="item"
                :reward-config="rewardConfig"
                @onFileDownload="downloadInputFile"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isDictionaryType()">
      <span class="label">{{ label }}:</span>
      <div class="indent border-box">
        <div v-for="child of children" :key="child.name">
          <ExecutionInputValue
            :name="child.name"
            :info="child"
            :reward-config="rewardConfig"
            @onFileDownload="downloadInputFile"
          />
        </div>
      </div>
    </div>
    <div v-if="isBooleanType()">
      <v-checkbox v-model="value" :label="label" disabled />
    </div>
    <div v-if="isListFilesType()">
      <span class="label">{{ label }}:</span>
      <v-list dense>
        <v-list-item v-for="(file_name, i) in value" :key="i">
          <v-list-item-content>
            <span class="downloadLink" @click="downloadInputFile(file_name)">
              {{ file_name }}
            </span>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
    <div v-if="isSelectType()">
      <v-form ref="form">
        <v-autocomplete
          v-model="value"
          :items="info.options ?? []"
          :label="label"
          item-value="value"
          item-text="text"
          readonly
        />
      </v-form>
    </div>
    <div v-if="type == 'label'">
      <h3>{{ text }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExecutionInputValue",
  components: {},
  props: {
    name: {
      type: String,
      default: "",
    },
    info: {
      type: Object,
      default: () => null,
    },
    rewardConfig: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      label: "",
      type: "",
      value: "",
      children: [],
      rewards: [],
    };
  },
  watch: {
    info: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  async mounted() {
    const self = this;
    this.label = "";
    this.initialize();
    // if (this.rewardConfig && this.name === "origin") {
    //   await this.api.getRewards(function (list) {
    //     self.rewards = list;
    //   });
    // }
  },
  methods: {
    isTextType() {
      // list-values is comma-separated values (= one line of text)
      return ["integer", "float", "string", "smiles", "text"].includes(
        this.type
      );
    },
    isListTextType() {
      return ["list-strings", "list-numbers"].includes(this.type);
    },
    isFileType() {
      return ["file"].includes(this.type);
    },
    isObjectType() {
      return ["yaml", "json"].includes(this.type);
    },
    isListType() {
      return ["list"].includes(this.type);
    },
    isDictionaryType() {
      return ["dictionary"].includes(this.type);
    },
    isBooleanType() {
      return ["boolean"].includes(this.type);
    },
    isListFilesType() {
      return ["list-files"].includes(this.type);
    },
    isSelectType() {
      return ["select"].includes(this.info.type);
    },
    initialize() {
      this.type = this.info.type;
      this.value = this.info.value;
      this.children = "children" in this.info ? this.info.children : [];
      if ("description" in this.info) {
        this.label = this.info.description.trim();
      }
      if (this.label.length === 0) {
        this.label = this.name;
      }
      if (this.isListTextType() && typeof this.value !== "string") {
        this.value = this.value.join(", ");
      }
    },
    downloadInputFile(fileName) {
      this.$emit("onFileDownload", fileName);
    },
  },
};
</script>

<style scoped>
.line {
  margin-top: 5px;
  margin-bottom: 5px;
}
.label {
  display: inline-block;
}
.border-box {
  padding-left: 10px;
  border-left: solid 1px;
}
.indent {
  margin-left: 50px;
}
</style>
