<template>
  <div>
    <nav>
      <v-app-bar app class="title-bar" dark dense>
        <v-app-bar-nav-icon class="nav-button" @click.stop="navDrawer = !navDrawer" />
        <v-toolbar-title>
          <slot name="title">{{ title }}</slot>
        </v-toolbar-title>
        <v-btn icon absolute right @click="openHelp">
          <v-icon>mdi-help-circle-outline</v-icon>
        </v-btn>
      </v-app-bar>
    </nav>

    <v-navigation-drawer v-model="navDrawer" app width="220">
      <v-list nav dense>
        <v-list-item v-for="(menu, i) in menuList" :key="i" :to="menu.linkTo">
          <v-list-item-icon>
            <v-icon>{{ menu.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ menu.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template #append>
        <v-divider />
        <v-list dense>
          <v-list-item class="v-list-item v-list-item--link theme--light">
            <v-list-item-icon>
              <v-icon>mdi-power</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <Logout />
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import Logout from '@/components/Logout';

export default {
  name: 'YamanishiMenu',
  components: {
    Logout: Logout
  },
  data() {
    return {
      navDrawer: null,
      menuList: [
        { linkTo: '/', title: 'Home', icon: 'mdi-home' },
        { linkTo: '/target_prediction/', title: 'Target Prediction', icon: 'mdi-bullseye-arrow' },
        { linkTo: '/pathway_prediction/', title: 'Pathway Prediction', icon: 'mdi-map-marker-path' },
        { linkTo: '/hit_generation/', title: 'Hit Generation', icon: 'mdi-compost' },
        { linkTo: '/lead_generation/', title: 'Lead Generation', icon: 'mdi-source-branch' },
        { linkTo: '/reaction_generation/', title: 'Reaction Generation', icon: 'mdi-ray-start-arrow' },
        { linkTo: '/executions/', title: 'Execution History', icon: 'mdi-table' }
      ]
    };
  },
  computed: {
    title: function() {
      return this.$route.meta.title;
    }
  },
  mounted() {
    const self = this;
    this.api.getUserInfo(function(response) {
      const user = response.data;
      self.$session.set('user', user);
      if (user.owned_groups.length > 0) {
        self.menuList.push({
          linkTo: '/groups',
          title: 'Manage Groups',
          icon: 'mdi-account-group'
        });
      }
    });
  },
  methods: {
    openHelp() {
      const pageUrl = `#page=${this.$route.meta.manualPage || 1}`;
      const manualUrl = this.$route.meta.manual || 'PlatformManual.pdf';
      window.open('/' + manualUrl + pageUrl, '_blank');
    }
  }
};
</script>

<style scoped>
.title-bar {
    background: linear-gradient(rgb(44, 201, 252), rgb(207, 242, 252));
    color:black;
}
::v-deep .theme--dark.v-btn.v-btn--icon{
    color: black;
}
</style>
