<template>
  <v-container class="pb-0">
    <v-card>
      <v-card-text>
        <v-row align="center" no-gutters>
          <v-col cols="2">
            <v-subheader class="pt-3 mt-1 ">
              Group
            </v-subheader>
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="selectedGroupId"
              :items="ownedGroups"
              label="Select Group"
              item-value="id"
              item-text="name"
              dense
            />
          </v-col>
        </v-row>
        <v-row align="center" no-gutters>
          <v-col cols="2">
            <v-subheader class="pt-3 mt-1 ">
              Add User
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <sup>
                    <v-icon
                      dense
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-help-circle-outline
                    </v-icon>
                  </sup>
                </template>
                <span>
                  ユーザーの追加は、文字列で入力できる。
                </span>
              </v-tooltip>
            </v-subheader>
          </v-col>
          <v-col cols="6">
            <v-autocomplete
              v-model="newMemberId"
              :items="availableUsers"
              item-text="username"
              item-value="id"
              clearable
            />
          </v-col>
          <v-col cols="3">
            <v-btn
              :loading="isAddingMember"
              class="ml-2"
              color="primary"
              @click="addNewMember"
            >
              Add
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <GroupMembersTable
      :group-id="selectedGroupId"
      :refresh="refresh"
      @refreshed="refresh=false"
      @memberRemoved="getNonMembers"
    />
  </v-container>
</template>

<script>
import GroupMembersTable from '@/components/GroupMembersTable.vue';
import { showSuccessDialog, showErrorDialog } from '@/mixins/utils';
export default {
  name: 'GroupManagement',
  components: {
    GroupMembersTable: GroupMembersTable
  },
  data() {
    return {
      ownedGroups: [],
      availableUsers: [],
      selectedGroupId: null,
      newMemberId: null,
      isAddingMember: false,
      refresh: false
    };
  },
  watch: {
    selectedGroupId: {
      handler: function() {
        this.getNonMembers();
      }
    }
  },
  mounted() {
    this.checkLoggedIn(this.$session);
    this.getOwnedGroups();
  },
  methods: {
    getOwnedGroups() {
      const currentuser = this.$session.get('user');
      this.ownedGroups = currentuser.owned_groups;
    },
    getNonMembers() {
      const self = this;
      if (this.selectedGroupId) {
        this.api.getNonMembers(this.selectedGroupId, function(response) {
          self.availableUsers = response.data;
        });
      }
    },
    addNewMember() {
      const self = this;
      this.isAddingMember = true;
      this.api.addGroupMember(
        this.selectedGroupId,
        this.newMemberId,
        function() {
          self.refresh = true;
          self.isAddingMember = false;
          self.newMemberId = null;
          self.getNonMembers();
          showSuccessDialog('A new member was added.');
        }, function() {
          self.isAddingMember = false;
          self.newMemberId = null;
          showErrorDialog('Member was not added.', 'An unexpected error occured');
        }
      );
    }
  }
};
</script>
